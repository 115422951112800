// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Content from './components/Content';
// import Blog from './components/Blog';
// import Category from './components/Category';
import DatabaseConfigPage from './components/DatabaseConfigPage';
import './App.css';
import hostInfo from './Servies';
console.log(hostInfo)

const App = () => {
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch quiz categories data
    fetch(hostInfo+"api/quizcategories")
    // console.log(hostInfo + 'api/quizcategories')
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    // Fetch quiz categories data
    fetch(hostInfo+"api/BlogTitle")
      .then((response) => response.json())
      .then((data) => setBlogs(data))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route
            path="/"
            element={<DatabaseConfigPage  />}
          />
          <Route
            path="/content"
            element={
              <Content categories={categories} blogs={blogs}/>
            }
          />
           {/* <Route
            path="/category/:categoryId"
            element={
              <Category/>
            }
          />
           <Route
            path="/blog/:blogId"
            element={
              <Blog />
            }
          /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
