import React, { useState ,useEffect} from 'react';
// import { useParams } from 'react-router-dom';
// import Sidebar from './Sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hostInfo from '../Servies';

const Category = ({ categoryId }) => {
// const { categoryId } = useParams();
  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
    // Fetch data based on categoryId
    if (categoryId) {
      fetch(hostInfo+`api/quiz/${categoryId}`)
        .then((response) => response.json())
        .then((data) => setCategoryData(data.data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [categoryId]);

    const [formData, setFormData] = useState({
        quizId:'',
        quizCategory: '',
        quizPicUrl: '',
        numOfQuestions: '',
        timerValue: '',
        correctAnsPoint: '',
        wrongAnsPoint: '',
        entryCoins: '',
        winCoins: '',
        coinText: '',
        categoryStatus: '',
        questions: [],
        quizCategoryId: '',
        quizCategoryName: '',
    });

    // console.log(formData)
    useEffect(() => {
        if (categoryData) {
            console.log(categoryData)
          setFormData({
            quizId:categoryData[0].quiz_id || '',
            quizCategory: categoryData[0].quiz_category || '',
            quizPicUrl: categoryData[0].quiz_pic_url || '',
            numOfQuestions: categoryData[0].num_of_questions || '',
            timerValue: categoryData[0].timer_value || '',
            correctAnsPoint: categoryData[0].correct_ans_point || '',
            wrongAnsPoint: categoryData[0].wrong_ans_point|| '',
            entryCoins: categoryData[0].entry_coins || '',
            winCoins: categoryData[0].win_coins || '',
            coinText: categoryData[0].coin_text || '',
            categoryStatus: categoryData[0].category_status || '',
            questions: categoryData[0].answers || [],
            quizCategoryId: categoryData[0].quiz_id || '',
            quizCategoryName: categoryData[0].quiz_category || '',
          });
        }
      }, [categoryData]);
      
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };
    
      const handleAddQuestion = () => {
        setFormData((prevData) => ({
          ...prevData,
          questions: [...prevData.questions, { question: '', answers: '', right_ans: '' ,question_status:''}]
        }));
      };
    

    const handleRemoveQuestion = async (index) => {
         // Get the question data from the local state (UI)
         const questionData = formData.questions[index];
         const qa_Id = questionData.qa_id;
        //  console.log(qa_Id);
    
        try {
            // Remove the question from the local state (UI)
            setFormData((prevData) => ({
                ...prevData,
                questions: prevData.questions.filter((_, i) => i !== index)
            }));
    
            // Remove the question from the database
            const response = await fetch(hostInfo+`api/removeQuiz/${qa_Id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                const responseData = await response.json();
                console.log('Delete successful:', responseData);
                // Show success notification
                toast.success('Question removed successfully!', { position: 'top-right' });
            } else {
                const errorData = await response.text();
                console.error('Error deleting question:', errorData);
                // Show error notification
                toast.error('Error removing question. Please try again.', { position: 'top-right' });
            }
        } catch (error) {
            console.error('Error:', error);
            // Show error notification
            toast.error('An unexpected error occurred. Please try again later.', { position: 'top-right' });
        }
    };
    

      const handleQuestionChange = (index, field, value) => {
        setFormData((prevData) => ({
          ...prevData,
          questions: prevData.questions.map((q, i) => (i === index ? { ...q, [field]: value } : q))
        }));
      };
    
      const handleAnswerChange = (index, field, value) => {
        console.log(field)
        setFormData((prevData) => ({
          ...prevData,
          questions: prevData.questions.map((q, i) =>
            i === index ? { ...q, [field]: value }  : q
          )
        }));
      };
      
      const handleSave = async () => {
        try {
            const response = await fetch(hostInfo+'api/saveData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
                
                // Show success notification at top-right
                toast.success('Data saved successfully!', { position: 'top-right' });
            } else {
                const errorData = await response.json();
                console.error(errorData);
                
                // Show error notification at top-right
                toast.error('Failed to save data. Please try again.', { position: 'top-right' });
            }
        } catch (error) {
            console.error('Error:', error);
            
            // Show error notification for unexpected errors
            toast.error('An unexpected error occurred. Please try again later.', { position: toast });
        }
    };
    
    
  return (
    <div className="container1" >
      {/* <Sidebar /> */}
      <form className="form1" style={{marginLeft:"20px",marginRight:"20px",backgroundColor:"#e0edf5"}}>
        <label>
            Quiz Id:
        <input
            type="text"
            name="quizId"  // Make sure the name attribute is "quizCategory"
            value={formData.quizId}
            onChange={handleChange}
        />
        </label>

        <label>
            Quiz Category:
        <input
            type="text"
            name="quizCategory"  // Make sure the name attribute is "quizCategory"
            value={formData.quizCategory}
            onChange={handleChange}
        />
        </label>

        <label>
            Quiz Picture URL:
            <input type="text" name="quizPicUrl" value={formData.quizPicUrl} onChange={handleChange} />
        </label>

        <label>
            Number of Questions:
            <input type="text" name="numOfQuestions" value={formData.numOfQuestions} onChange={handleChange} />
        </label>

        <label> Timer Value:
            <input type="text" name="timerValue" value={formData.timerValue} onChange={handleChange} />
        </label>

        <label>
            Correct Answer Points:
            <input type="text" name="correctAnsPoint" value={formData.correctAnsPoint} onChange={handleChange} />
        </label>

        <label>
            Wrong Answer Points:
            <input type="text" name="wrongAnsPoint" value={formData.wrongAnsPoint} onChange={handleChange} />
        </label>

        <label>
            Entry Coins:
            <input type="text" name="entryCoins" value={formData.entryCoins} onChange={handleChange} />
        </label>

        <label>
            Win Coins:
            <input type="text" name="winCoins" value={formData.winCoins} onChange={handleChange} />
        </label>

        <label>
            Coin Text:
            <input type="text" name="coinText" value={formData.coinText} onChange={handleChange} />
        </label>

        <label>
            Category Status:
            <input type="text" name="categoryStatus" value={formData.categoryStatus} onChange={handleChange} />
        </label>

        <label>
            Quiz Category Id:
            <input type="text" name="quizCategoryId" value={formData.quizCategoryId} onChange={handleChange} />
        </label>
    
        <label>
            Quiz Category Name:
            <input type="text" name="categoryStatus" value={formData.quizCategoryName} onChange={handleChange} />
        </label>
    
        {/* Display questions dynamically */}
        {formData.questions.map((question, index) => (
          <div key={index}>
            <label>
              Question {index + 1}:
              <input
                type="text"
                name={`question-${index}`}
                value={question.question}
                onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
              />
            </label>

            <label>
                Answers :
                <input
                  type="text"
                  name={`answers-${index}`}
                  value={question.answers}
                  onChange={(e) => handleAnswerChange(index, 'answers', e.target.value)}
                />
            </label>
            {/* ))} */}

            <label>
              Right Answer:
              <input
                type="text"
                name={`right_ans-${index}`}
                value={question.right_ans}
                onChange={(e) => handleQuestionChange(index, 'right_ans', e.target.value)}
              />
            </label>

            <label>
            Question Status:
              <input
                type="text"
                name={`question_status-${index}`}
                value={question.question_status}
                onChange={(e) => handleQuestionChange(index, 'question_status', e.target.value)}
              />
            </label>

            {/* Remove button for each question */}
            <button type="button" style={{backgroundColor:"red"}} onClick={() => handleRemoveQuestion(index)}>
              Remove
            </button>
          </div>
        ))}

        {/* Button to add more questions */}
        <button type="button" onClick={handleAddQuestion}>
          Add More Questions
        </button>

        {/* Save button */}
        <button type="button" onClick={handleSave}>
          Save
        </button>
        
      </form>
    </div>
  );
};

export default Category;
