import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import hostInfo from '../Servies';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DatabaseConfigPage = () => {
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [database, setDatabase] = useState('');
  const navigate = useNavigate();

  const handleSave = () => {
    const configData = { host, port, username, password, database };

    fetch(hostInfo+'api/saveConfiguration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(configData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Response from server:', data);
        toast.success('Server connected successfully!');
        navigate('/content',);
      })
      .catch(error => {
        console.error('Error sending configuration to server:', error);
        toast.error('Server connection failed!');
      });
  };

  return (
    <div className="container">
      <form>
      <div style={{textAlign:"center",alignItems:"center", marginBottom:"10px"}}>
      <img
            title="Quizpopa"
            alt="Quizpopa"
            fetchPriority="high"  
            width="auto"
            height="50"
            decoding="async"
            data-nimg="1"
            src={require("../assets/Quizpopa_logo.png")} 
            style={{ color: 'transparent', margin:"1px"}}
          />  
      </div>
        <div>
          <label>Host:</label>
          <input type="text" value={host} onChange={(e) => setHost(e.target.value)} />
        </div>
        <div>
          <label>Port:</label>
          <input type="text" value={port} onChange={(e) => setPort(e.target.value)} />
        </div>
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div>
          <label>Database:</label>
          <input type="text" value={database} onChange={(e) => setDatabase(e.target.value)} />
        </div>
        <button type="button" onClick={handleSave}>
          Save Configuration
        </button>
      </form>
    </div>
  );
};

export default DatabaseConfigPage;
