// Blog.js
import React, { useState ,useEffect} from 'react';
// import { useParams } from 'react-router-dom';
// import Sidebar from './Sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hostInfo from '../Servies';

const Blog = ({ blogId }) => {
// const { blogId } = useParams();
  const [blogData, setblogData] = useState(null);

  useEffect(() => {
    // Fetch data based on blogId
    if (blogId) {
      fetch( hostInfo+`api/blog/${blogId}`)
        .then((response) => response.json())
        .then((data) => setblogData(data.data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [blogId]);

    const [formData, setFormData] = useState({
        Id:'',
        title	: '',
        descriptions: '',
        image: '',
        blogs: [],
    });

    useEffect(() => {
        if (blogData) {
            console.log(blogData)
          setFormData({
            Id:blogData[0].id || '',
            title: blogData[0].title || '',
            descriptions: blogData[0].descriptions || '',
            image: blogData[0].image || '',
            blogs: blogData[0].blogs || [],           
          });
        }
      }, [blogData]);
      
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };
    
    //   const handleAddQuestion = () => {
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       blogs: [...prevData.blogs, { article_id: '', article_category: '', article_title: '' ,article_image:'',paragraph1:'',paragraph2:'',paragraph3:''}]
    //     }));
    //   };
    const handleAddQuestion = () => {
        setFormData((prevData) => ({
          ...prevData,
          blogs: [
            ...prevData.blogs,
            {
              article_id: '',
              article_category: '',
              article_title: '',
              article_image: '',
              paragraph1: '',
              paragraph2: '',
              paragraph3: '',
            },
          ],
        }));
      };    
    
      const handleRemoveQuestion = async (index) => {
        // Get the blog data from the local state (UI)
        const blogData = formData.blogs[index];
        const article_Id = blogData.article_id;
        
        try {
            // Remove the blog from the local state (UI)
            setFormData((prevData) => ({
                ...prevData,
                blogs: prevData.blogs.filter((_, i) => i !== index)
            }));
    
            // Remove the blog from the database
            const response = await fetch(hostInfo+`api/removeBlog/${article_Id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                const responseData = await response.json();
                console.log('Delete successful:', responseData);
                // Show success notification
                toast.success('Content removed successfully!', { position: 'top-right' });
            } else {
                const errorData = await response.text();
                console.error('Error deleting content:', errorData);
                // Show error notification
                toast.error('Error removing content. Please try again.', { position: 'top-right' });
            }
        } catch (error) {
            console.error('Error:', error);
            // Show error notification
            toast.error('An unexpected error occurred. Please try again later.', { position: 'top-right' });
        }
    };
    const handleQuestionChange = (index, field, value) => {
        setFormData((prevData) => ({
          ...prevData,
          blogs: prevData.blogs.map((q, i) => (i === index ? { ...q, [field]: value } : q)),
        }));
      };
    
    //   const handleAnswerChange = (index, field, value) => {
    //     console.log(field)
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       blogs: prevData.blogs.map((q, i) =>
    //         i === index ? { ...q, [field]: value }  : q
    //       )
    //     }));
    //   };
      
      const handleSave = async () => {
        try {
            const response = await fetch(hostInfo+'api/saveBlog', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
                
                // Show success notification at top-right
                toast.success('Data saved successfully!', { position: 'top-right' });
            } else {
                const errorData = await response.json();
                console.error(errorData);
                
                // Show error notification at top-right
                toast.error('Failed to save data. Please try again.', { position: 'top-right' });
            }
        } catch (error) {
            console.error('Error:', error);
            
            // Show error notification for unexpected errors
            toast.error('An unexpected error occurred. Please try again later.', { position: toast });
        }
    };
    
    
  return (
    <div className="container1" >
      {/* <Sidebar/> */}
      <form className="form1" style={{marginLeft:"20px",marginRight:"20px",backgroundColor:"#e0edf5"}}>
        <label>
            Id:
        <input
            type="text"
            name="Id"  // Make sure the name attribute is "quizCategory"
            value={formData.Id}
            onChange={handleChange}
        />
        </label>

        <label>
            Title:
        <input
            type="text"
            name="title"  // Make sure the name attribute is "quizCategory"
            value={formData.title}
            onChange={handleChange}
        />
        </label>

        <label>
            Descriptions:
            <input type="text" name="descriptions" value={formData.descriptions} onChange={handleChange} />
        </label>

        <label>
            Image:
            <input type="text" name="image" value={formData.image} onChange={handleChange} />
        </label>
        
        {/* Display questions dynamically */}
        {formData.blogs.map((blog, index) => (
          <div key={index}>
            {/* <label>
            Article Id {index + 1}:
              <input
                type="text"
                name={`article_id-${index}`}
                value={blog.article_id}
                onChange={(e) => handleQuestionChange(index, 'article_id', e.target.value)}
              />
            </label> */}

            {/* <label>
            Article Category :
                <input
                  type="text"
                  name={`article_category-${index}`}
                  value={blog.article_category}
                  onChange={(e) => handleAnswerChange(index, 'article_category', e.target.value)}
                />
            </label> */}

            <label>
                Article Title:
              <input
                type="text"
                name={`article_title-${index}`}
                value={blog.article_title}
                onChange={(e) => handleQuestionChange(index, 'article_title', e.target.value)}
              />
            </label>

            <label>
                 Article Image:
              <input
                type="text"
                name={`article_image-${index}`}
                value={blog.article_image}
                onChange={(e) => handleQuestionChange(index, 'article_image', e.target.value)}
              />
            </label>

            <label>
                Paragraph 1:
              <input
                type="text"
                name={`paragraph1-${index}`}
                value={blog.paragraph1}
                onChange={(e) => handleQuestionChange(index, 'paragraph1', e.target.value)}
              />
            </label> 

            <label>
                Paragraph 2:
              <input
                type="text"
                name={`paragraph2-${index}`}
                value={blog.paragraph2}
                onChange={(e) => handleQuestionChange(index, 'paragraph2', e.target.value)}
              />
            </label>

             <label>
                 Paragraph 3:
              <input
                type="text"
                name={`paragraph3-${index}`}
                value={blog.paragraph3}
                onChange={(e) => handleQuestionChange(index, 'paragraph3', e.target.value)}
              />
            </label> 
          
            {/* Remove button for each question */}
            <button type="button" style={{backgroundColor:"red"}} onClick={() => handleRemoveQuestion(index)}>
              Remove
            </button>
          </div>
        ))}

        {/* Button to add more questions */}
        <button type="button" onClick={handleAddQuestion}>
          Add More Content
        </button>

        {/* Save button */}
        <button type="button" onClick={handleSave}>
          Save
        </button>
        
      </form>
    </div>
  );
};

export default Blog;
