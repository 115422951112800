import React from 'react';
import {} from 'react-router-dom';
import Sidebar from './Sidebar';
import Blog from './Blog'; // Import the Blog component
import Category from './Category'; // Import the Category component
// import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

const Content = ({categories,blogs}) => {
  const location = useLocation();
  const { state } = location;
  const blogId = state && state.blogId;
  const categoryId = state && state.categoryId;

  // Determine whether to show the content based on the presence of blogId and categoryId
  const showContent = !blogId && !categoryId;

  // const [categories, setCategories] = useState([]);
  // const [blogs, setBlogs] = useState([]);

  // useEffect(() => {
  //   // Fetch quiz categories data
  //   fetch('http://localhost:3005/api/quizcategories')
  //     .then((response) => response.json())
  //     .then((data) => setCategories(data))
  //     .catch((error) => console.error('Error fetching categories:', error));
  // }, []);

  // useEffect(() => {
  //   // Fetch quiz categories data
  //   fetch('http://localhost:3005/api/BlogTitle')
  //     .then((response) => response.json())
  //     .then((data) => setBlogs(data))
  //     .catch((error) => console.error('Error fetching categories:', error));
  // }, []);


  return (
    <div className="container1" >
      <Sidebar categories={categories} blogs={blogs} />
      {showContent ? (
        <div className="form1" style={{ marginLeft: "20px", marginRight: "20px", backgroundColor: "#e0edf5", height: "100%" }}>
          [1] Click on a Quiz Categories to see category list.<br />
          [2] Click on a Quiz Blogs to see blog list.
        </div>
      ) : (
        <>
          {/* Conditionally render the Category component based on the presence of categoryId */}
          {categoryId && <Category categoryId={categoryId} />}

          {/* Conditionally render the Blog component based on the presence of blogId */}
          {blogId && <Blog blogId={blogId} />}
        </>
      )}
    </div>
  );
};

export default Content;
