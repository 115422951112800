// Sidebar.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Sidebar.css';
import hostInfo from '../Servies';

const Sidebar = ({ categories,blogs }) => {
  const navigate = useNavigate();
  const [activeList, setActiveList] = useState('quizcategories'); // Default to quizcategories
  const [newCategory, setNewCategory] = useState('');
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [newBlog, setNewBlog] = useState('');
  const [isAddingBlog, setIsAddingBlog] = useState(false);
  const [applicationSettings, setApplicationSettings] = useState({});
// console.log(applicationSettings)

  const handleSaveClick = () => {
    if (activeList === 'quizcategories') {
      // setActiveList('quizcategories');
      handleAddCategory();
    } else if (activeList === 'quizblog') {
      // setActiveList('quizblog');
      handleAddBlog();
    }
  };

  const handleBlogClick = (blogId) => {
    setActiveList('quizblog');
    navigate(`/content`, { state: { blogId } });
  };

  const handleCategoryClick = (categoryId) => {
    setActiveList('quizcategories');
    navigate(`/content`, { state: { categoryId } });
  };

  const handleAddBlogClick = () => {
    setIsAddingBlog(true);
  };
  const handleAddCategoryClick = () => {
    // Set state to indicate that the user is adding a category
    setIsAddingCategory(true);
  };
  
  const handleListChange = (list) => {
    // Change the active list
    navigate("/content");
    setActiveList(list);
  };

  const handleAddBlog = async () => {
    // Make a POST request to your API to add the new category
    try {
      const response = await fetch(hostInfo+"api/addBlogs", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ blog: newBlog }),
      });

      const data = await response.json();
      // Assuming your API returns the new category with an ID                                   
      if (data && data.id) {
      }

      setNewBlog('');
      // Set state to indicate that the user has finished adding a category
        setIsAddingBlog(false);
        window.location.reload()
    } catch (error) {
        console.error('Error adding Blog:', error);
    }
  };

  const handleAddCategory = async () => {
    // Make a POST request to your API to add the new category
    try {
      const response = await fetch(hostInfo+"api/addCategory", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ category: newCategory }),
      });

      const data = await response.json();
      // Assuming your API returns the new category with an ID
      if (data && data.id) {
      }

      setNewCategory('');
      // Set state to indicate that the user has finished adding a category
      setIsAddingCategory(false);
      window.location.reload()

    } catch (error) {
      console.error('Error adding category:', error);
    }
  };


  const fetchApplicationSettings = async () => {
    try {
      const response = await fetch(hostInfo+"api/applicationSettings");
      const data = await response.json();
      // console.log(data)
      setApplicationSettings(data);
    } catch (error) {
      console.error('Error fetching application settings:', error);
    }
  };
  
  useEffect(() => {
    fetchApplicationSettings();
  }, []);
  

  return (
    <div className="sidebar" style={{ cursor: 'pointer',textAlign:"start" }}>
      {/* List Options */}
      <div style={{width:"auto",marginLeft:"-20px",marginRight:"-20px",borderBottom:"2px solid #a9c8ea"}}>
        <span style={{ cursor: 'pointer',color: activeList === 'quizcategories' ? '#0056b3' : 'black',borderRight:"2px solid #a9c8ea"}} onClick={() => handleListChange('quizcategories')}>
          Quiz Categories
        </span>
        <span style={{ cursor: 'pointer',color: activeList === 'quizblog' ? '#0056b3' : 'black'}} onClick={() => handleListChange('quizblog')}>
          Quiz Blog
        </span>
      </div>
  
      {/* List Items */}
      {activeList === 'quizcategories' && (
        <ul style={{marginTop:"25px"}}>
          {categories && categories.data &&
            categories.data.map((category) => (
              <li key={category.quiz_id} onClick={() => handleCategoryClick(category.quiz_id)}>
                <span style={{ cursor: 'pointer', margin: '-5px 0px' }}>{category.quiz_category}</span>
              </li>
            ))}
        </ul>
      )}
      {activeList === 'quizblog' && (
        <ul style={{marginTop:"25px"}}>                                  
          {blogs && blogs.data &&
            blogs.data.map((blog) => (
              <li key={blog.id} onClick={() => handleBlogClick(blog.id)}>
                <span style={{ cursor: 'pointer', margin: '-5px 0px' }}>{blog.title}</span>
              </li>
            ))}
        </ul>
      )}
  
      {/* Add Category/Form Section */}
      {(isAddingCategory || isAddingBlog) && (
        <div className="add-category-form">
          <input
            style={{ marginBottom: '0px', marginLeft: '5px', marginTop: '5px' }}
            type="text"
            placeholder={`Enter new ${activeList === 'quizcategories' ? 'category' : 'blog'}`}
            value={activeList === 'quizcategories' ? newCategory : newBlog}
            onChange={(e) =>
              activeList === 'quizcategories' ? setNewCategory(e.target.value) : setNewBlog(e.target.value)
            }
          />
          <button onClick={handleSaveClick}>Save</button>
        </div>
      )}

      {/* Add Category Button */}
      {(!isAddingCategory && activeList === 'quizcategories') || (!isAddingBlog && activeList === 'quizblog') ? (
        <button onClick={activeList === 'quizcategories' ? handleAddCategoryClick : handleAddBlogClick}>
          Add {activeList === 'quizcategories' ? 'Category' : 'Blog'}
        </button>
      ) : null}

{applicationSettings && applicationSettings.data &&(
  <div style={{ marginTop: "30px" }}>
    <label style={{ fontSize: "20px", fontWeight: "bolder" }}>All Application Settings:</label>
    {applicationSettings.data.map((setting) => (
      <div key={setting.id} style={{ marginBottom: "-15px" }}>
        <label style={{ fontSize: "18px" }}>{setting.key}:</label>
        <input
          type="checkbox"
          name=  {setting.key}
          checked={setting.value === "True"}
          onChange={async (e) => {
            const updatedValue = e.target.checked ? "True" : "False";
            const updatedSettings = applicationSettings.data.map((item) =>
              item.id === setting.id ? { ...item, value: updatedValue } : item
            );
            setApplicationSettings({ ...applicationSettings, data: updatedSettings });
            // Send a request to update the value in your SQL table
            try {
              const response = await fetch(hostInfo+"api/updateSetting", {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: setting.id,
                  value: updatedValue,
                }),
              });
                // Handle the response if needed
                const result = await response.json();
                console.log(result);
              // Handle the response if needed
            } catch (error) {
              console.error("Error updating setting:", error);
            }
          }}
          style={{ width: "15px", height: "15px" }}
        />
      </div>
    ))}
  </div>
)}

    </div>
  );
};

export default Sidebar;






